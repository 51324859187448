import gsap from "gsap";
import {CustomEase} from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);

export const SetZoom = () => {

    /*const main = document.querySelector("main");
    var zoomSpeed = 0.3;
    var prevZoom = 1;
    var nextZoom;
    var zoomside = "regular";

    main.addEventListener('click', function (e) {

        var zoomTo = Number(document.body.dataset.zoomto);
        if (zoomTo === 2) {
            // Zoom in to level 2
            document.body.dataset.zoom = 0.5;
            nextZoom = 3;

        } else if (zoomTo === 3) {
            // Zoom in to level 3
            zoomside = "reverse";
            if (prevZoom == 1) {
                prevZoom = 3
                document.body.dataset.zoom = 1;
            } else if (prevZoom == 3) {
                // Back Zoom  to level 1
                document.body.dataset.zoom = 4;
                prevZoom = 1;
                zoomside = "regular";
            }
            nextZoom = 2;
        }

        document.body.dataset.zoomto = nextZoom;
        document.body.dataset.zoomside = zoomside;

        const targetSlide = e.target.parentElement.parentElement;
        var zoomRatio = document.body.dataset.zoom ? document.body.dataset.zoom : 1;
        const slideContainer = document.querySelector(".slide-container.lenis");

        gsap.to(main, {
            x: 0,     // Translate in the X direction
            y: 0,     // Translate in the Y direction
            z: 0,
            scale: zoomRatio,
            duration: zoomSpeed,
            ease: CustomEase.create("custom", "M0,0 C0.42,0 0.58,1 1,1"),
            onUpdate: function() {
                main.style.transformOrigin = "50% "+"50"+"%";
            }
        });
    });*/
}

