import gsap from "gsap";

export const SetMenu = () => {
    const AboutToggler = document.querySelector('.about');
    const AboutClose = document.querySelector('.close-btn');
    const HomeToggler = document.querySelector('.about-overlay--header .gotohome');
    AboutToggler.addEventListener('click', function (e) {
        e.preventDefault();

        const $nav = document.querySelector("nav");

        gsap.to($nav, {
            duration: 0.15, opacity: 0,
        });

        const $menuOverlay = document.querySelector(".menu-overlay");

        gsap.to($menuOverlay, {
            duration: 0.3, opacity: 0, pointerEvents: "none", overflow: "hidden",
        });

        const $el = document.querySelector(".about-overlay");

        gsap.to($el, {
            duration: 0.3, opacity: 1, pointerEvents: "auto", overflow: "scroll",
        });
    });

    AboutClose.addEventListener('click', function (e) {
        e.preventDefault();
        const $el = document.querySelector(".about-overlay");

        gsap.to($el, {
            duration: 0.3, opacity: 0, pointerEvents: "none", overflow: "hidden",
        });

        const $nav = document.querySelector("nav");

        gsap.to($nav, {
            duration: 0, opacity: 1,
        });
    });

    HomeToggler.addEventListener('click', function (e) {
        e.preventDefault();
        const $el = document.querySelector(".about-overlay");
        gsap.to($el, {
            duration: 0.3, opacity: 0, pointerEvents: "none", overflow: "hidden",
        });
    });


    const ProjectsToggler = document.querySelector('.scroll-legend');
    const ProjectClose = document.querySelector('.menu-overlay');

    ProjectsToggler.addEventListener('mouseenter', function (e) {
        e.preventDefault();

        const $el = document.querySelector(".menu-overlay");

        gsap.to($el, {
            duration: 0.3, opacity: 1, pointerEvents: "auto", overflow: "scroll",
        });
        gsap.to(ProjectsToggler, {
            duration: 0.3, opacity: 0, pointerEvents: "auto",
        });
    });

    ProjectClose.addEventListener('click', function (e) {
        e.preventDefault();
        const $el = document.querySelector(".menu-overlay");

        if (e.target === $el) {
            gsap.to($el, {
                duration: 0.3, opacity: 0, pointerEvents: "none", overflow: "hidden",
                onComplete: function (){
                    $el.scrollTop = 0;
                }
            });
        }

        gsap.to(ProjectsToggler, {
            duration: 0.3, opacity: 1, pointerEvents: "auto",
        });
    });

    const ProjectsListItem = document.querySelectorAll('.projects-list-item');

    ProjectsListItem.forEach((elem) => {
        const link = elem.querySelector('a');
        elem.addEventListener('click', function (e) {
            const $el = document.querySelector(".menu-overlay");
            gsap.to($el, {
                duration: 0.3, opacity: 0, pointerEvents: "none", overflow: "hidden",
            });
        });

        const isSamePageLink = (link) => {
            const currentUrl = window.location.href.split('#')[0];  // Exclude hash
            const targetUrl = new URL(link.href, window.location.origin).href.split('#')[0];  // Convert to absolute and exclude hash
            return currentUrl === targetUrl;
        };

        link.addEventListener('click', function (e) {
            if (isSamePageLink(link)) {
                e.preventDefault()
            } else {
                document.querySelector("nav").classList.add('hidden');
            }
        });
    });


    /*gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".projects-list-item").forEach(item => {
        gsap.to(item, {
            scrollTrigger: {
                trigger: item, // Each item acts as its own trigger
                scroller: ".menu-overlay", // The scrollable container
                start: "top center", // Start when the top of the item reaches the center of the container
                end: "bottom center", // End when the bottom of the item reaches the center
                onEnter: () => gsap.to(item, { color: "black" }), // Change color to grey on enter
                onLeave: () => gsap.to(item, { color: "#e6e6e6" }), // Reset color on leave (optional)
                onLeaveBack: () => gsap.to(item, { color: "#e6e6e6" }), // Reset color on leave back (scrolling up)
                onEnterBack: () => gsap.to(item, { color: "black" }), // Change color to g
            }
        });
    });*/


}


