

__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Swup from 'swup';
import gsap from "gsap";
import Panzoom from '@panzoom/panzoom';



import {SetMenu} from "./_modules/menu";
import {SetCursor} from "./_modules/cursor";
import {SetCustomScroll} from "./_modules/LoopScroll";
import {SetZoom} from "./_modules/zoom";



document.addEventListener("DOMContentLoaded", () => {
    onLoading();
    loadPage();

    SetMenu();
    SetCursor();
    SetCustomScroll();
    SetZoom();


    const swup = new Swup({
        containers: ["#swup"]
    });


    swup.hooks.on('page:view', () => {
        onLoading();
        loadPage();
        SetCustomScroll();
        SetZoom();
    });




});