import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import Typewriter from 'typewriter-effect/dist/core';
import {CustomEase} from "gsap/CustomEase";
import Flickity from "flickity";
import {TypeWriter} from "./typewriter";
import 'flickity/css/flickity.css';

require('flickity-imagesloaded');
require('flickity-fullscreen');

gsap.registerPlugin(CustomEase);

// Function to find the slide closest to the center of the viewport

export const SetCustomScroll = () => {

    var appearValue = "80%"

    gsap.registerPlugin(ScrollTrigger);

    /*REFRESH SCROLL AFTER ZOOM*/

    var hasScroll = false;
    document.querySelector("main").addEventListener("scroll", function () {
        hasScroll = true;
        ScrollTrigger.refresh();
    }, {once: true});

    window.addEventListener("resize", function () {
        ScrollTrigger.refresh();
    });

    const main = document.querySelector(".slide-container");
    var currentTitle = document.querySelector(".slides:first-of-type").dataset.title;

    const pageContainer = document.querySelector("main");
    const pageTemplate = pageContainer.dataset.template;

    if (pageTemplate == "home") {

        const typeContainer = document.querySelector('#typewriter');
        var typewriter = new Typewriter(typeContainer, {
            autoStart: true,  // Start typing immediately upon mounting
            loop: false,      // Do not repeat the text
            cursor: '',      // Customize the cursor character if desired
            delay: 10,        // Typing speed// Pause after typing (if desired)
        });

        gsap.utils.toArray(".slides").forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item, // Each item acts as its own trigger
                    scroller: ".main", // The scrollable container
                    start: "top center", // Start when the top of the item reaches the center of the container
                    end: "bottom center", // End when the bottom of the item reaches the center
                    onEnter: () => {
                        if (currentTitle == item.dataset.title && hasScroll === false) {
                            typewriter?.pasteString(item.dataset.title).start().callFunction(function () {
                                document.querySelector("nav").classList.remove("hidden");
                            });

                            return false;
                        } else {
                            if (document.querySelector(".Typewriter__wrapper").innerText !== item.dataset.title) {
                                if (typewriter) {
                                    typewriter.state.eventQueue = [];
                                }
                                typewriter?.deleteAll(10).start();
                                typewriter?.typeString(item.dataset.title).start();
                                document.querySelector("nav").classList.remove("hidden");
                            }
                        }
                    },
                    onLeave: () => {
                        if (typewriter) {
                            typewriter.state.eventQueue = [];
                        }
                        typewriter?.deleteAll(10).start();
                    },
                    onEnterBack: () => {
                        if (typewriter) {
                            typewriter.state.eventQueue = [];
                        }
                        typewriter?.deleteAll(10).start();
                        typewriter?.typeString(item.dataset.title).start();
                    }
                }
            });
        });
    } else {
        const typeContainer = document.querySelector('#typewriter');
        typeContainer.innerHTML = document.querySelector(".slides:first-of-type").dataset.title;
        document.querySelector("nav").classList.remove("hidden");
    }

    gsap.utils.toArray(".slides").forEach(item => {
        gsap.to(item, {
            scrollTrigger: {
                trigger: item, // Each item acts as its own trigger
                scroller: ".main", // The scrollable container
                start: "top " + appearValue, // Start when the top of the item reaches the center of the container
                onEnter: () => {
                    item.classList.remove('blurred');
                },
            }
        });
    });


    /*SET ZOOM*/

    var zoomSpeed = 0.3;
    var prevZoom = 1;
    var nextZoom;
    var zoomside = "regular";


    document.querySelector('main').addEventListener("scroll", function () {
        var scrollTop = document.querySelector("main").scrollTop + (document.body.clientHeight / 2);
        /*console.log("scroll top " + scrollTop + "px");*/
    });

    if (!document.documentElement.classList.contains('touch')) {
        main.addEventListener('click', function (e) {
            var scrollTop = document.querySelector("main").scrollTop + (document.body.clientHeight / 2);
            var originalHeight = main.scrollHeight
            var scrollTopPercentage = (scrollTop / originalHeight) * 100;

            main.style.transformOrigin = "50% " + scrollTopPercentage + "%";

            if (scrollTopPercentage < 10) {
                main.style.transformOrigin = "50% " + 0 + "%";
                var scrollToAfter = 0;
            } else if (scrollTopPercentage > 90) {
                main.style.transformOrigin = "50% " + 100 + "%";
                var scrollToAfter = main.scrollHeight;
            }

            var width;

            var zoomTo = Number(document.body.dataset.zoomto);
            if (zoomside === "regular") {
                if (zoomTo === 2) {
                    // Zoom in to level 2
                    document.body.dataset.zoom = 0.5;
                    width = "50%";
                    nextZoom = 3;
                } else if (zoomTo === 3) {
                    document.body.dataset.zoom = 0.32;
                    width = "16%";
                    nextZoom = 2;
                    zoomside = "reverse";
                }
            } else if (zoomside === "reverse") {
                if (zoomTo === 2) {
                    document.body.dataset.zoom = 3.125;
                    width = "50%";
                    nextZoom = 1;
                } else if (zoomTo === 1) {
                    document.body.dataset.zoom = 2;
                    width = "100%";
                    nextZoom = 2;
                    zoomside = "regular";
                }
            }

            if (width == "50%") {
                appearValue = "90%";

            } else if (width == "16%") {
                appearValue = "100%";

            } else {
                appearValue = "80%";
            }

            gsap.utils.toArray(".slides").forEach(item => {
                gsap.to(item, {
                    scrollTrigger: {
                        trigger: item, // Each item acts as its own trigger
                        scroller: ".main", // The scrollable container
                        start: "top " + appearValue, // Start when the top of the item reaches the center of the container
                        onEnter: () => {
                            item.classList.remove('blurred');
                        },
                    }
                });
            });

            document.body.dataset.zoomto = nextZoom;
            document.body.dataset.zoomside = zoomside;

            var zoomRatio = document.body.dataset.zoom ? document.body.dataset.zoom : 1;


            console.log('height =' + document.querySelector("main").scrollHeight);

            var updatedScroll;
            gsap.to(main, {
                scale: zoomRatio,
                duration: zoomSpeed,
                onComplete: function () {
                    document.querySelector("main").scrollTop = updatedScroll / zoomRatio;
                    gsap.to(main, {
                        width: width,
                        scale: 1,
                        duration: 0,
                    });

                    if (scrollToAfter === 0) {
                        document.querySelector('main').scrollTop = 0;
                    } else if (scrollToAfter) {
                        document.querySelector('main').scrollTop = ((scrollTopPercentage / 100) * document.querySelector("main").scrollHeight) - (document.body.clientHeight / 2);
                    } else {
                        document.querySelector('main').scrollTop = ((scrollTopPercentage / 100) * document.querySelector("main").scrollHeight) - (document.body.clientHeight / 2);
                    }
                    ScrollTrigger.refresh();
                }
            });
        });
    }


    /*gsap.utils.toArray(".slides").forEach(item => {

      var img = item.querySelector('img');
        gsap.set(img, {filter: "blur(50px)"});
        gsap.to(img, {
            filter: "blur(0px)",
            scrollTrigger: {
                scroller: "main",
                trigger: img,
                start: "top bottom",
                end: "top top",
                scrub: true,
            }
        });
    });*/

    // INIT CAROUSEL WHEN HOMEPAGE

    const slideshows = document.querySelectorAll(".carousel");


    slideshows.forEach((elem) => {

        if (pageTemplate == "home") {
            typewriter.state.eventQueue = [];
            typewriter?.deleteAll(0).start();
        } else {
            document.querySelector("nav").classList.remove("hidden");
            const menuEL = document.querySelectorAll('.menu-el');
            menuEL.forEach((elem) => {
                elem.classList.remove('animate');
            });
        }


        var flkty = new Flickity(elem, {
            cellAlign: 'center',
            contain: true,
            lazyLoad: 2,
            autoPlay: 150,
            fade: true,
            selectedAttraction: 1,
            pauseAutoPlayOnHover: false,
            friction: 1,
            pageDots: false,
            prevNextButtons: false,
            on: {
                change: function () {
                    if (this.selectedIndex === 0) {
                        console.log('Reached the start of the carousel');
                    } else if (this.selectedIndex === this.slides.length - 1) {

                        var slideshow = this;
                        setTimeout(function (){
                            slideshow.destroy();
                            slideshow.element.remove();

                        const menuEL = document.querySelectorAll('.menu-el a');

                        const siteLogo = document.querySelector('.site-logo');
                        gsap.to(siteLogo, {
                            opacity: 0,
                            duration: 0.3,
                            delay: 0.1,
                            onComplete: function () {
                                const centerSlide = document.querySelector(".slides:first-of-type");
                                document.querySelector("nav").classList.remove("hidden");
                                if (pageTemplate == "home") {
                                    menuEL.forEach((elem) => {
                                        TypeWriter(elem);
                                    });
                                    document.querySelector('#typewriter').parentElement.classList.add('completed');
                                    document.querySelector('#typewriter .Typewriter__wrapper').innerHTML = "";
                                    typewriter?.typeString(centerSlide.dataset.title).start();
                                }
                            }
                        });
                        }, 150);
                    }
                }
            }
        });
    });

}
